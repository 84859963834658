<template>
  <section class="dataDashboard" ref="dataDashboard">
      <section class="dashboard2" ref="dashboard2" >
        <div class="d-row">

          <el-row class="d-menu-row">
            <el-col v-for="(c, i) in countData" :span="5" :offset="(i%4)>0?1:0" class="item">
              <img :src="c.imgSrc"  />
              <div class="item-div">
                <span class="item-count">{{sysCount[c.key]>-1?sysCount[c.key]:'加载中'}}</span>
                <span class="item-name">{{c.name}}</span>
              </div>
            </el-col>
          </el-row>
        </div>

      </section>
      <div class="ScanExamList">
        <div class="search_container searchArea">
          <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
            <el-form-item label="学年学期" >
              <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
                <el-option key="" label="全部" value=""></el-option>
                <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="查找">
              <el-input v-model="searchForm.searchText" placeholder="学校或考试名称"  @keyup.enter.native='loadLicenceList'></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="info" size ="mini" icon="search" @click='loadLicenceList'>查询</el-button>
            </el-form-item>

          </el-form>
        </div>
        <div class="table_container">
          <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>

            <el-table-column prop="semester" label="学年学期" min-width="250" align='center' ></el-table-column>
            <el-table-column prop="category" label="类型" :formatter="formatterExamType" align='center' ></el-table-column>
            <el-table-column prop="id" label="考试ID" align='center' ></el-table-column>
            <el-table-column prop="name" label="考试名称" min-width="350" align='center' ></el-table-column>
            <el-table-column prop="state" label="状态"  width="150" :formatter="formatterState" align='center' ></el-table-column>
            <el-table-column prop="teacher_name" label="创建者" align='center' ></el-table-column>
            <el-table-column prop="sf_count" label="待处理卷数" align='center'></el-table-column>
            <el-table-column prop="kg_count" label="已识别卷数" align='center'></el-table-column>

          </el-table>
          <pagination  :page-size="searchForm.pageSize" :current-page="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>

      </div>

  </section>
</template>

<script>

  import {mapState, mapActions} from 'vuex'
  import * as mUtils from '@/utils/mUtils'
  import Pagination from "@/components/pagination";
  import * as simpleApi from "request/simple";

  export default {
    data(){
      return {
        tableData: [], tableHeight:0,
        loading:true, acadyearTermList: [],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, semester: null,},
        countData: [{
          name: "二级单位", imgSrc: require("assets/dashboard/index_base.png"),  key: 'school_count',
        }, {
          name: "课程数", imgSrc: require("assets/dashboard/course.jpeg"),  key: 'course_count',
        }, {
          name: "行政班数", imgSrc: require("assets/dashboard/my_class.jpeg"),  key: 'hard_class_count',
        }, {
          name: "学生数", imgSrc: require("assets/dashboard/student.jpeg"), key: 'student_count',
        },{
          name: "教师数",  imgSrc: require("assets/dashboard/teacher.jpeg"), key: 'teacher_count',
        }, {
          name: "考试数", imgSrc: require("assets/dashboard/exam.jpg"), key: 'exam_count',
        }, {
          name: "扫描数", imgSrc: require("assets/dashboard/scan.jpg"),  key: 'log_count',
        }],
        sysCount: { exam_count: -1, log_count: -1, licence_count: -1, school_count: -1, course_count: -1
          , hard_class_count: -1, student_count: -1, teacher_count: -1, },

      }
    },
    components: {
      Pagination
    },
    computed:{
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      headNavHeight(){
        return document.body.clientHeight - 160;
      },
    },
    created(){
    },
    mounted(){
      this.loadOne();
      this.loadAcadyearTermList();
      this.loadLicenceList();
    },
    methods: {

      loadOne(){
        console.log('load one', this.deviceId)
        let param = { controllerName: 'adminScan/', methodName: 'sysCount', param: { licenceId: this.userInfo.licenceId }};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) => {
          if(data){

            this.sysCount = { exam_count: data.exam_count, log_count: data.log_count,
              licence_count: data.licence_count, school_count: data.school_count, course_count: data.course_count
                    , hard_class_count: data.hard_class_count, student_count: data.student_count,
              teacher_count: data.teacher_count, };

          }
        });
      },
      loadLicenceList(){
        this.loading = true;

        let search = { searchText: mUtils.searchText(this.searchForm.searchText), semester: mUtils.searchText(this.searchForm.semester),licenceId: this.userInfo.licenceId, preCount: 'Y', orderBy: 't.licence_key, t.id desc'};
        let param = { controllerName: 'adminScan', methodName: '/examList2', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log(data)
          if (data.code == 0) {
            this.tableData = data.result;
            this.searchForm.pageNo = data.pageNo;
            this.searchForm.pageSize = data.pageSize;
            this.searchForm.totalCount = data.totalCount;
            this.sysCount.exam_count = data.totalCount;
          }
          this.loading = false;
        });
      },
      loadAcadyearTermList(){
        this.acadyearTermList = [];
        let now = new Date();
        let year = parseInt(now.getFullYear());
        for(let i=(year-3); i<(year+2); i++){
          this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
          this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
        }
      },
      formatterState(row, column, cellValue, index) {
        if(cellValue == 0) return '新建';
        if(cellValue == 1) return '阅卷中';
        if(cellValue == 2) return '阅卷结束';
        if(cellValue == 3) return '任务分配完成';
        if(cellValue == 4) return '阅卷中';
        if(cellValue == 5) return '阅卷结束';
        return cellValue;

      },
      formatterExamType(row, column, v, index){
        if(v == 0) return "期中";
        if(v == 1) return "期末";
        if(v == 2) return "补考";
        if(v == 3) return "测验";
        if(v == 4) return "其他";
        return v;
      },
      // 上下分页
      handleCurrentChange(val){
        this.searchForm.pageNo = val;
        this.loadLicenceList()
      },
      // 每页显示多少条
      handleSizeChange(val){
        this.searchForm.pageSize = val;
        this.loadLicenceList()
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
  .dataDashboard{
    border-radius: 2px;
    .row_list{
      .row_base{
        padding: 10px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 6px;
        height: 120px;
      }
    }

    .dashboard2{
      padding: 0px 10px 10px 10px;

      .d-row {
        padding: 10px 10px;
        background-color: white;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        .d-title{
          text-align: left;
          padding-left: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(150, 151, 153, 0.23);
          span{
            padding: 10px 10px;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .d-menu-row {
          .item{
            padding-left: 10px;
            margin-top: 10px;
            text-align: left;
            display: flex;
            flex-direction: row;
            float: left;
            img{
              width: 50px; height: 50px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              border-radius: 10px;
            }
            .item-div{
              display: flex;
              flex-direction: column;
                .item-name {
                  flex: 1;
                  margin-left: 10px;
                  font-size: 14px;
                }
                .item-count {
                  flex: 2;
                  margin-left: 10px;
                  font-weight: bold;
                  font-size: 24px;
                  color: red;
                }
            }
          }
            vertical-align: middle;
          span {
            margin-left: 20px;
          }
          a {
            color: black;
          }
        }
      }

    }
    .ScanExamList{
      .search_container{
      }
      .btnRight{
        float: right;
        margin-right: 0px !important;
      }
      .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
      }
      .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
      }
      .el-dialog--small{
        width: 600px !important;
      }
      .pagination{
        text-align: left;
        margin-top: 10px;
      }
    }

  }

</style>
